import React from "react";
import { useNavigate } from "react-router-dom";
import { GoBackSvg, Icons } from "../../../assets";
import {
  formatThreadsWithLevels,
  getDeviceHeight,
  trackEvent,
} from "../../../utils/functions";
import SideBarItem from "../SideBarItem";
import "./styles.css";
import { useSelector } from "react-redux";

export const getNewThreadStyle = (currentThreadId, previousThread) => {
  if (currentThreadId == previousThread) {
    return {
      containerStyle: "w-[100%] bg-newPrimary mt-1 mb-2 px-[14px] py-4",
      icon: Icons.chatFill,
      activeStyle: "!text-white",
    };
  }
  return {
    containerStyle:
      "w-[100%] bg-white mt-1 mb-2 px-[14px] py-4 border-none hover:bg-newPrimary/20",
    icon: Icons.chat,
    activeStyle: "!text-black",
  };
};

const SideBar = ({
  data,
  onPressCreate,
  onPressWorkspace,
  onCloneWorkspace,
  onChangeThread,
  currentThreadId,
  creatingThread,
  setCreatingThread,
  isViewer,
  roomId,
  projectId,
}) => {
  const formattedThreads = formatThreadsWithLevels(data);
  const navigate = useNavigate();
  const { selectedTeam } = useSelector((state) => state.user);
  const showBottomScroller = formattedThreads?.some(
    (item) => item?.subthreads?.length > 0
  );

  return (
    <div
      className="relative bg-white flex flex-col items-center  custom-shadow"
      style={{
        height: getDeviceHeight(),
      }}
    >
      <div className="px-4 w-full">
        {!isViewer ? (
          <SideBarItem
            icon={Icons.plus}
            iconStyle={"w-2.5 h-2.5"}
            heading={"New thread"}
            containerStyle={"w-[100%] mt-4 mb-4 px-4 py-4"}
            onPress={() => {
              onPressCreate();
              setCreatingThread(true);
            }}
          />
        ) : (
          <span className="block mt-3" />
        )}
      </div>
      <div className="px-4 flex flex-1 flex-col overflow-y-scroll w-full h-full ">
        <div className="flex flex-1 flex-col overflow-x-scroll w-full h-full items-center new-thread-container ">
          {creatingThread && (
            <SideBarItem
              containerStyle={"w-[100%] bg-newPrimary mt-1 mb-2 px-[14px] py-4"}
              activeStyle={"!text-white"}
              icon={Icons.chatFill}
              iconStyle={"w-3 h-3"}
              heading={"New Thread"}
            />
          )}
          {formattedThreads?.map((item) => (
            <SideBarItem
              key={item?.id}
              containerStyle={
                getNewThreadStyle(currentThreadId, item?.id).containerStyle
              }
              icon={getNewThreadStyle(currentThreadId, item?.id).icon}
              iconStyle={"w-3 h-3"}
              activeStyle={
                getNewThreadStyle(currentThreadId, item?.id).activeStyle
              }
              heading={item?.name}
              currentThreadId={currentThreadId}
              checkId={item.id}
              onPress={() => {
                onChangeThread(item?.id);
                setCreatingThread(false);
              }}
              subthreads={item?.subthreads}
              onChangeThread={onChangeThread}
              setCreatingThread={setCreatingThread}
            />
          ))}
        </div>
      </div>
      <div className="w-[100%] mt-4 flex flex-col justify-end pb-[6.5%] md:pb-[45px] relative ">
        {showBottomScroller && (
          <div className="pointer-events-none thread-gradient w-full h-[119px] bottom-[105%] absolute "></div>
        )}
        <SideBarItem
          containerStyle={"flex border-none px-4"}
          iconStyle={"w-5 h-5 "}
          icon={Icons.workspaces}
          heading={"Back to Workspace"}
          onPress={() => {
            trackEvent("Chat - Back to Workspace", {
              teamID: selectedTeam?.teamId,
              projectID: projectId,
              fileID: roomId,
            });
            navigate(`/teams/${selectedTeam.teamId}`);
          }}
        />
      </div>
    </div>
  );
};

export default SideBar;
