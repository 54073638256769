import ProfilePic from "../../common/ProfilePic";
import TextArea from "../../common/TextArea";
import CustomBtn from "../../common/CustomBtn";
import Input from "../../common/Input";
import SettingNav from "../../common/SettingNav";
import CommonModal from "../../common/CommonModal";
import { useEffect, useState } from "react";
import { Colors } from "../../../themes";
import { RegText, SemiBoldText } from "../../../styles";
import { CircularProgress, Stack } from "@mui/material";
import {
  validateInput,
  validateConfirmInput,
} from "../../../helper/validation";
import { Services } from "../../../services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../libraries/firebase";

import { styles } from "./styles";
import { trackEvent } from "../../../utils/functions";

const SettingProfileScreen = () => {
  const { selectedTeam, data } = useSelector((state) => state.user);
  const [image, setImage] = useState(selectedTeam?.profileImage);
  const [teamName, setTeamName] = useState("");
  const [teamNameError, setTeamNameError] = useState("");
  const [confirmTeamName, setConfirmTeamName] = useState(null);
  const [confirmTeamNameError, setConfirmTeamNameError] = useState(null);
  const [teamAbout, setTeamAbout] = useState("");
  const [aboutTeamError, setAboutTeamError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeletedModal, setOpenDeletedModal] = useState(false);
  const [isFormChange, setIsFormChange] = useState(false);
  const [imageError, setImageError] = useState("");
  const currentUser = auth?.currentUser;
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUser?.uid
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!openDeleteModal) {
      console.log("openDeleteModal", openDeleteModal);
      setConfirmTeamNameError("");
    }
  }, [openDeleteModal]);

  const handleDelete = async (response) => {
    if (response) {
      setLoading(true);
      setOpenDeleteModal(false);
      const res = await Services.TeamServices.deleteTeam(
        selectedTeam?.teamId,
        data.displayName
      );
      if (res === "success") {
        trackEvent("Team Profile - Delete team", {
          teamID: selectedTeam?.teamId,
        });
        setOpenDeletedModal(true);
        setConfirmTeamNameError("");
        setConfirmTeamName("");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedTeam?.teamId) return;

    if (!isAdmin) {
      navigate("/");
    }
  }, [selectedTeam, isAdmin]);

  const handleDeleteTeam = () => {
    const response = validateConfirmInput(
      confirmTeamName,
      setConfirmTeamNameError,
      "Team Name",
      teamName
    );
    handleDelete(response);
  };

  useEffect(() => {
    setImage(selectedTeam?.profileImage);
    setTeamName(selectedTeam?.teamName);
    setTeamAbout(selectedTeam?.teamAbout);
  }, [selectedTeam]);

  const renderDeletedModal = () => {
    return (
      <CommonModal
        heading="Team deleted"
        subHeading="You & your team members have no access to that project."
        open={openDeletedModal}
        setOpen={setOpenDeletedModal}
      >
        <CustomBtn
          type="primary"
          onClick={() => {
            setOpenDeletedModal(false);
            navigate("/");
          }}
        >
          Close
        </CustomBtn>
      </CommonModal>
    );
  };
  const renderDeleteModal = () => {
    return (
      <CommonModal
        heading="Delete team?"
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
      >
        <RegText variant="body2" sx={{ color: Colors.dark, mb: "40px" }}>
          All your Projects and Files will be lost, and if you have a
          subscription plan, there will be no refund.
          <RegText
            component="span"
            variant="body2"
            sx={{ color: Colors.black, ml: "2px" }}
          >
            Type in the team name to continue.
          </RegText>
        </RegText>
        <Input
          style={{ width: "100%" }}
          label="Team name"
          type="text"
          value={confirmTeamName}
          onChange={(e) => setConfirmTeamName(e.target.value)}
        />
        {confirmTeamNameError && (
          <RegText
            sx={{
              color: Colors.errorColor,
              fontSize: "14px",
              mt: "5px",
            }}
          >
            {confirmTeamNameError}
          </RegText>
        )}
        <Stack mt="40px" gap="24px">
          <CustomBtn
            disabled={loading || !confirmTeamName}
            onClick={handleDeleteTeam}
            type="error"
          >
            {loading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Delete"
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </CustomBtn>
        </Stack>
      </CommonModal>
    );
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const isImageChanged = selectedTeam?.profileImage !== image;
      const teamId = selectedTeam?.teamId;
      const res = await Services.TeamServices.updateTeam(
        teamId,
        teamName,
        teamAbout,
        image,
        isImageChanged
      );

      if (res === "success") {
        trackEvent("Team Profile - Edit Profile");
        setIsFormChange(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e,
    setInputValue,
    setError,
    setState,
    fieldName
  ) => {
    const inputValue = e.target.value;
    validateInput(inputValue, setError, fieldName);
    setInputValue(inputValue);
    setState(true);
  };
  useEffect(() => {
    if (image !== selectedTeam?.profileImage) {
      setIsFormChange(true);
      return;
    }
  }, [image]);
  return (
    <>
      {renderDeleteModal()}
      {renderDeletedModal()}
      <Stack height="100vh" px={"20px"}>
        <SettingNav />
        <SemiBoldText
          sx={{
            color: Colors.white,
            display: { xs: "block", md: "none" },
            textAlign: "center",
            mt: "16px",
            mb: "40px",
          }}
          variant="body1"
        >
          Team Profile
        </SemiBoldText>
        <Stack alignItems="center">
          <ProfilePic
            image={image}
            setImage={setImage}
            setImageError={setImageError}
          />
          {imageError && (
            <RegText sx={styles.errorMessage}>{imageError}</RegText>
          )}
          <Stack gap="24px" mt="31px" width={{ xs: "95%", md: "440px" }}>
            <Stack gap="10px">
              <Input
                label="Team name"
                type="text"
                value={teamName}
                onChange={(e) =>
                  handleInputChange(
                    e,
                    setTeamName,
                    setTeamNameError,
                    setIsFormChange,
                    "Team name"
                  )
                }
              />
              {teamNameError && (
                <RegText
                  sx={{
                    color: Colors.errorColor,
                    fontSize: "14px",
                  }}
                >
                  {teamNameError}
                </RegText>
              )}
            </Stack>
            <Stack>
              <Input
                label="About"
                placeholder="Add description"
                value={teamAbout}
                onChange={(e) =>
                  handleInputChange(
                    e,
                    setTeamAbout,
                    setAboutTeamError,
                    setIsFormChange,
                    "About team"
                  )
                }
                marginBottom={10}
                multiline={true}
                rows={5}
              />
              {aboutTeamError && (
                <RegText
                  sx={{
                    color: Colors.errorColor,
                    fontSize: "14px",
                    mt: "10px",
                  }}
                >
                  {aboutTeamError}
                </RegText>
              )}
            </Stack>
          </Stack>
          <Stack
            gap="16px"
            mt={{ xs: "150px", md: "40px" }}
            width={{ xs: "95%", md: "440px" }}
          >
            <CustomBtn
              onClick={handleSave}
              disabled={
                isLoading ||
                !isFormChange ||
                teamName.trim().length < 2 ||
                teamAbout.trim().length > 300
              }
              type={"primary"}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: Colors.white }} size={30} />
              ) : (
                "Save Changes"
              )}
            </CustomBtn>
            <CustomBtn type="delete" onClick={() => setOpenDeleteModal(true)}>
              Delete team
            </CustomBtn>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SettingProfileScreen;
