import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Provider } from "react-redux";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import Room from "./components/pages/Room";
import SharedRoom from "./components/pages/SharedRoom";
import { Services } from "./services";
import HomeScreen from "./components/pages/HomeScreen";
import Navigation from "./components/common/Navigation";
import InvitationScreen from "./components/pages/InvitationScreen";
import MemberPage from "./components/pages/MemberPage";
import SettingProfileScreen from "./components/pages/SettingProfileScreen";
import ShowMembersScreen from "./components/pages/ShowMembersScreen";
import SignupScreen from "./components/pages/SignupScreen";
import SigninScreen from "./components/pages/SigninScreen";
import BillingScreen from "./components/pages/BillingScreen";
import { store } from "./redux/store";
import WorkSpaces from "./components/pages/WorkSpaces";
import Profile from "./components/pages/Profile";
import CreateTeamScreen from "./components/pages/CreateTeamScreen";
import PrivateRoutes from "./ProtectedRoutes";
import ExpiredScreen from "./components/pages/ExpiredScreen";
import InviteCancelScreen from "./components/pages/InviteCancelScreen";
import ViewerInvitation from "./components/pages/ViewerInvitation";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";

function App() {
  let theme = createTheme();
  useEffect(() => {
    async function initializeMixpanelKey() {
      const response = await Services.SettingsServices.getMixpanelKey();
      if (response) {
        mixpanel.init(response);
      }
    }
    initializeMixpanelKey();
  }, []);

  theme = responsiveFontSizes(theme);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              exact
              path="/teams/:teamId/r/:id"
              element={
                <PrivateRoutes>
                  <Room />
                </PrivateRoutes>
              }
            />
            <Route
              exact
              path="/create-team/:step?"
              element={
                <PrivateRoutes>
                  <CreateTeamScreen />
                </PrivateRoutes>
              }
            />
            <Route
              exact
              path="/share/:id"
              element={
                <PrivateRoutes>
                  <SharedRoom />
                </PrivateRoutes>
              }
            />
            <Route
              exact
              path="/signup"
              element={
                <PrivateRoutes>
                  <SignupScreen />
                </PrivateRoutes>
              }
            />
            <Route exact path="/signin" element={<SigninScreen />} />
            <Route
              exact
              path="/"
              element={
                <Navigation>
                  <HomeScreen />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:invitedTeamId/invitation"
              element={
                <Navigation>
                  <InvitationScreen />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:id"
              element={
                <Navigation>
                  <WorkSpaces />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:id/member/:id"
              element={
                <Navigation>
                  <MemberPage />
                </Navigation>
              }
            />
            <Route
              exact
              path="/viewer-invitation/:teamId"
              element={
                <Navigation>
                  <ViewerInvitation />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:id/members"
              element={
                <Navigation>
                  <ShowMembersScreen />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:id/settings"
              element={
                <Navigation>
                  <SettingProfileScreen />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:id/subscriptions"
              element={
                <Navigation>
                  <BillingScreen />
                </Navigation>
              }
            />
            <Route
              exact
              path="/user-profile"
              element={
                <Navigation>
                  <Profile />
                </Navigation>
              }
            />
            <Route
              exact
              path="/teams/:invitedTeamId/invitation/expire"
              element={
                <Navigation>
                  <ExpiredScreen />
                </Navigation>
              }
            />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/terms-of-use" element={<Terms />} />
            <Route
              exact
              path="/teams/:invitedTeamId/invitation/cancel"
              element={
                <Navigation>
                  <InviteCancelScreen />
                </Navigation>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
