import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import testSlice from "./testSlice";
import userSlice from "./userSlice";
const enableDevTools = process.env.REACT_APP_DEVTOOLS === "true";
export const store = configureStore({
  reducer: {
    app: appSlice,
    test: testSlice,
    user: userSlice,
  },
  devTools: enableDevTools,
});
